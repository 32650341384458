.height-1 {
    height: calc(10.8px * var(--scaleHeight));
  }
  .height-2 {
    height: calc(21.6px * var(--scaleHeight));
  }
  .height-3 {
    height: calc(32.4px * var(--scaleHeight));
  }
  .height-4 {
    height: calc(43.2px * var(--scaleHeight));
  }
  .height-5 {
    height: calc(54px * var(--scaleHeight));
  }
  .height-6 {
    height: calc(64.8px * var(--scaleHeight));
  }
  .height-7 {
    height: calc(75.6px * var(--scaleHeight));
  }
  .height-8 {
    height: calc(86.4px * var(--scaleHeight));
  }
  .height-9 {
    height: calc(97.2px * var(--scaleHeight));
  }
  .height-10 {
    height: calc(108px * var(--scaleHeight));
  }
  .height-11 {
    height: calc(118.8px * var(--scaleHeight));
  }
  .height-12 {
    height: calc(129.6px * var(--scaleHeight));
  }
  .height-13 {
    height: calc(140.4px * var(--scaleHeight));
  }
  .height-14 {
    height: calc(151.2px * var(--scaleHeight));
  }
  .height-15 {
    height: calc(162px * var(--scaleHeight));
  }
  .height-16 {
    height: calc(172.8px * var(--scaleHeight));
  }
  .height-17 {
    height: calc(183.6px * var(--scaleHeight));
  }
  .height-18 {
    height: calc(194.4px * var(--scaleHeight));
  }
  .height-19 {
    height: calc(205.2px * var(--scaleHeight));
  }
  .height-20 {
    height: calc(216px * var(--scaleHeight));
  }
  .height-21 {
    height: calc(226.8px * var(--scaleHeight));
  }
  .height-22 {
    height: calc(237.6px * var(--scaleHeight));
  }
  .height-23 {
    height: calc(248.4px * var(--scaleHeight));
  }
  .height-24 {
    height: calc(259.2px * var(--scaleHeight));
  }
  .height-25 {
    height: calc(270px * var(--scaleHeight));
  }
  .height-26 {
    height: calc(280.8px * var(--scaleHeight));
  }
  .height-27 {
    height: calc(291.6px * var(--scaleHeight));
  }
  .height-28 {
    height: calc(302.4px * var(--scaleHeight));
  }
  .height-29 {
    height: calc(313.2px * var(--scaleHeight));
  }
  .height-30 {
    height: calc(324px * var(--scaleHeight));
  }
  .height-31 {
    height: calc(334.8px * var(--scaleHeight));
  }
  .height-32 {
    height: calc(345.6px * var(--scaleHeight));
  }
  .height-33 {
    height: calc(356.4px * var(--scaleHeight));
  }
  .height-34 {
    height: calc(367.2px * var(--scaleHeight));
  }
  .height-35 {
    height: calc(378px * var(--scaleHeight));
  }
  .height-36 {
    height: calc(388.8px * var(--scaleHeight));
  }
  .height-37 {
    height: calc(399.6px * var(--scaleHeight));
  }
  .height-38 {
    height: calc(410.4px * var(--scaleHeight));
  }
  .height-39 {
    height: calc(421.2px * var(--scaleHeight));
  }
  .height-40 {
    height: calc(432px * var(--scaleHeight));
  }
  .height-41 {
    height: calc(442.8px * var(--scaleHeight));
  }
  .height-42 {
    height: calc(453.6px * var(--scaleHeight));
  }
  .height-43 {
    height: calc(464.4px * var(--scaleHeight));
  }
  .height-44 {
    height: calc(475.2px * var(--scaleHeight));
  }
  .height-45 {
    height: calc(486px * var(--scaleHeight));
  }
  .height-46 {
    height: calc(496.8px * var(--scaleHeight));
  }
  .height-47 {
    height: calc(507.6px * var(--scaleHeight));
  }
  .height-48 {
    height: calc(518.4px * var(--scaleHeight));
  }
  .height-49 {
    height: calc(529.2px * var(--scaleHeight));
  }
  .height-50 {
    height: calc(540px * var(--scaleHeight));
  }
  .height-51 {
    height: calc(550.8px * var(--scaleHeight));
  }
  .height-52 {
    height: calc(561.6px * var(--scaleHeight));
  }
  .height-53 {
    height: calc(572.4px * var(--scaleHeight));
  }
  .height-54 {
    height: calc(583.2px * var(--scaleHeight));
  }
  .height-55 {
    height: calc(594px * var(--scaleHeight));
  }
  .height-56 {
    height: calc(604.8px * var(--scaleHeight));
  }
  .height-57 {
    height: calc(615.6px * var(--scaleHeight));
  }
  .height-58 {
    height: calc(626.4px * var(--scaleHeight));
  }
  .height-59 {
    height: calc(637.2px * var(--scaleHeight));
  }
  .height-60 {
    height: calc(648px * var(--scaleHeight));
  }
  .height-61 {
    height: calc(658.8px * var(--scaleHeight));
  }
  .height-62 {
    height: calc(669.6px * var(--scaleHeight));
  }
  .height-63 {
    height: calc(680.4px * var(--scaleHeight));
  }
  .height-64 {
    height: calc(691.2px * var(--scaleHeight));
  }
  .height-65 {
    height: calc(702px * var(--scaleHeight));
  }
  .height-66 {
    height: calc(712.8px * var(--scaleHeight));
  }
  .height-67 {
    height: calc(723.6px * var(--scaleHeight));
  }
  .height-68 {
    height: calc(734.4px * var(--scaleHeight));
  }
  .height-69 {
    height: calc(745.2px * var(--scaleHeight));
  }
  .height-70 {
    height: calc(756px * var(--scaleHeight));
  }
  .height-71 {
    height: calc(766.8px * var(--scaleHeight));
  }
  .height-72 {
    height: calc(777.6px * var(--scaleHeight));
  }
  .height-73 {
    height: calc(788.4px * var(--scaleHeight));
  }
  .height-74 {
    height: calc(799.2px * var(--scaleHeight));
  }
  .height-75 {
    height: calc(810px * var(--scaleHeight));
  }
  .height-76 {
    height: calc(820.8px * var(--scaleHeight));
  }
  .height-77 {
    height: calc(831.6px * var(--scaleHeight));
  }
  .height-78 {
    height: calc(842.4px * var(--scaleHeight));
  }
  .height-79 {
    height: calc(853.2px * var(--scaleHeight));
  }
  .height-80 {
    height: calc(864px * var(--scaleHeight));
  }
  .height-81 {
    height: calc(874.8px * var(--scaleHeight));
  }
  .height-82 {
    height: calc(885.6px * var(--scaleHeight));
  }
  .height-83 {
    height: calc(896.4px * var(--scaleHeight));
  }
  .height-84 {
    height: calc(907.2px * var(--scaleHeight));
  }
  .height-85 {
    height: calc(918px * var(--scaleHeight));
  }
  .height-86 {
    height: calc(928.8px * var(--scaleHeight));
  }
  .height-87 {
    height: calc(939.6px * var(--scaleHeight));
  }
  .height-88 {
    height: calc(950.4px * var(--scaleHeight));
  }
  .height-89 {
    height: calc(961.2px * var(--scaleHeight));
  }
  .height-90 {
    height: calc(972px * var(--scaleHeight));
  }
  .height-91 {
    height: calc(982.8px * var(--scaleHeight));
  }
  .height-92 {
    height: calc(993.6px * var(--scaleHeight));
  }
  .height-93 {
    height: calc(1004.4px * var(--scaleHeight));
  }
  .height-94 {
    height: calc(1015.2px * var(--scaleHeight));
  }
  .height-95 {
    height: calc(1026px * var(--scaleHeight));
  }
  .height-96 {
    height: calc(1036.8px * var(--scaleHeight));
  }
  .height-97 {
    height: calc(1047.6px * var(--scaleHeight));
  }
  .height-98 {
    height: calc(1058.4px * var(--scaleHeight));
  }
  .height-99 {
    height: calc(1069.2px * var(--scaleHeight));
  }
  .height-100 {
    height: calc(1080px * var(--scaleHeight));
  }
  
  .height-1-p { height: 1% }
  .height-2-p { height: 2% }
  .height-3-p { height: 3% }
  .height-4-p { height: 4% }
  .height-5-p { height: 5% }
  .height-6-p { height: 6% }
  .height-7-p { height: 7% }
  .height-8-p { height: 8% }
  .height-9-p { height: 9% }
  .height-10-p { height: 10% }
  .height-11-p { height: 11% }
  .height-12-p { height: 12% }
  .height-13-p { height: 13% }
  .height-14-p { height: 14% }
  .height-15-p { height: 15% }
  .height-16-p { height: 16% }
  .height-17-p { height: 17% }
  .height-18-p { height: 18% }
  .height-19-p { height: 19% }
  .height-20-p { height: 20% }
  .height-21-p { height: 21% }
  .height-22-p { height: 22% }
  .height-23-p { height: 23% }
  .height-24-p { height: 24% }
  .height-25-p { height: 25% }
  .height-26-p { height: 26% }
  .height-27-p { height: 27% }
  .height-28-p { height: 28% }
  .height-29-p { height: 29% }
  .height-30-p { height: 30% }
  .height-31-p { height: 31% }
  .height-32-p { height: 32% }
  .height-33-p { height: 33% }
  .height-34-p { height: 34% }
  .height-35-p { height: 35% }
  .height-36-p { height: 36% }
  .height-37-p { height: 37% }
  .height-38-p { height: 38% }
  .height-39-p { height: 39% }
  .height-40-p { height: 40% }
  .height-41-p { height: 41% }
  .height-42-p { height: 42% }
  .height-43-p { height: 43% }
  .height-44-p { height: 44% }
  .height-45-p { height: 45% }
  .height-46-p { height: 46% }
  .height-47-p { height: 47% }
  .height-48-p { height: 48% }
  .height-49-p { height: 49% }
  .height-50-p { height: 50% }
  .height-51-p { height: 51% }
  .height-52-p { height: 52% }
  .height-53-p { height: 53% }
  .height-54-p { height: 54% }
  .height-55-p { height: 55% }
  .height-56-p { height: 56% }
  .height-57-p { height: 57% }
  .height-58-p { height: 58% }
  .height-59-p { height: 59% }
  .height-60-p { height: 60% }
  .height-61-p { height: 61% }
  .height-62-p { height: 62% }
  .height-63-p { height: 63% }
  .height-64-p { height: 64% }
  .height-65-p { height: 65% }
  .height-66-p { height: 66% }
  .height-67-p { height: 67% }
  .height-68-p { height: 68% }
  .height-69-p { height: 69% }
  .height-70-p { height: 70% }
  .height-71-p { height: 71% }
  .height-72-p { height: 72% }
  .height-73-p { height: 73% }
  .height-74-p { height: 74% }
  .height-75-p { height: 75% }
  .height-76-p { height: 76% }
  .height-77-p { height: 77% }
  .height-78-p { height: 78% }
  .height-79-p { height: 79% }
  .height-80-p { height: 80% }
  .height-81-p { height: 81% }
  .height-82-p { height: 82% }
  .height-83-p { height: 83% }
  .height-84-p { height: 84% }
  .height-85-p { height: 85% }
  .height-86-p { height: 86% }
  .height-87-p { height: 87% }
  .height-88-p { height: 88% }
  .height-89-p { height: 89% }
  .height-90-p { height: 90% }
  .height-91-p { height: 91% }
  .height-92-p { height: 92% }
  .height-93-p { height: 93% }
  .height-94-p { height: 94% }
  .height-95-p { height: 95% }
  .height-96-p { height: 96% }
  .height-97-p { height: 97% }
  .height-98-p { height: 98% }
  .height-99-p { height: 99% }
  .height-100-p { height: 100% }
  
  .height-max {
    height: 100vh;
  }
  
  .height-auto {
    height: 100%;
  }
  
  .height-disable {
    height: 0%;
  }
  
  .width-1 {
    width: calc(19.2px * var(--scale));
  }
  .width-2 {
    width: calc(38.4px * var(--scale));
  }
  .width-3 {
    width: calc(57.6px * var(--scale));
  }
  .width-4 {
    width: calc(76.8px * var(--scale));
  }
  .width-5 {
    width: calc(96px * var(--scale));
  }
  .width-6 {
    width: calc(115.2px * var(--scale));
  }
  .width-7 {
    width: calc(134.4px * var(--scale));
  }
  .width-8 {
    width: calc(153.6px * var(--scale));
  }
  .width-9 {
    width: calc(172.8px * var(--scale));
  }
  .width-10 {
    width: calc(192px * var(--scale));
  }
  .width-11 {
    width: calc(211.2px * var(--scale));
  }
  .width-12 {
    width: calc(230.4px * var(--scale));
  }
  .width-13 {
    width: calc(249.6px * var(--scale));
  }
  .width-14 {
    width: calc(268.8px * var(--scale));
  }
  .width-15 {
    width: calc(288px * var(--scale));
  }
  .width-16 {
    width: calc(307.2px * var(--scale));
  }
  .width-17 {
    width: calc(326.4px * var(--scale));
  }
  .width-18 {
    width: calc(345.6px * var(--scale));
  }
  .width-19 {
    width: calc(364.8px * var(--scale));
  }
  .width-20 {
    width: calc(384px * var(--scale));
  }
  .width-21 {
    width: calc(403.2px * var(--scale));
  }
  .width-22 {
    width: calc(422.4px * var(--scale));
  }
  .width-23 {
    width: calc(441.6px * var(--scale));
  }
  .width-24 {
    width: calc(460.8px * var(--scale));
  }
  .width-25 {
    width: calc(480px * var(--scale));
  }
  .width-26 {
    width: calc(499.2px * var(--scale));
  }
  .width-27 {
    width: calc(518.4px * var(--scale));
  }
  .width-28 {
    width: calc(537.6px * var(--scale));
  }
  .width-29 {
    width: calc(556.8px * var(--scale));
  }
  .width-30 {
    width: calc(576px * var(--scale));
  }
  .width-31 {
    width: calc(595.2px * var(--scale));
  }
  .width-32 {
    width: calc(614.4px * var(--scale));
  }
  .width-33 {
    width: calc(633.6px * var(--scale));
  }
  .width-34 {
    width: calc(652.8px * var(--scale));
  }
  .width-35 {
    width: calc(672px * var(--scale));
  }
  .width-36 {
    width: calc(691.2px * var(--scale));
  }
  .width-37 {
    width: calc(710.4px * var(--scale));
  }
  .width-38 {
    width: calc(729.6px * var(--scale));
  }
  .width-39 {
    width: calc(748.8px * var(--scale));
  }
  .width-40 {
    width: calc(768px * var(--scale));
  }
  .width-41 {
    width: calc(787.2px * var(--scale));
  }
  .width-42 {
    width: calc(806.4px * var(--scale));
  }
  .width-43 {
    width: calc(825.6px * var(--scale));
  }
  .width-44 {
    width: calc(844.8px * var(--scale));
  }
  .width-45 {
    width: calc(864px * var(--scale));
  }
  .width-46 {
    width: calc(883.2px * var(--scale));
  }
  .width-47 {
    width: calc(902.4px * var(--scale));
  }
  .width-48 {
    width: calc(921.6px * var(--scale));
  }
  .width-49 {
    width: calc(940.8px * var(--scale));
  }
  .width-50 {
    width: calc(960px * var(--scale));
  }
  .width-51 {
    width: calc(979.2px * var(--scale));
  }
  .width-52 {
    width: calc(998.4px * var(--scale));
  }
  .width-53 {
    width: calc(1017.6px * var(--scale));
  }
  .width-54 {
    width: calc(1036.8px * var(--scale));
  }
  .width-55 {
    width: calc(1056px * var(--scale));
  }
  .width-56 {
    width: calc(1075.2px * var(--scale));
  }
  .width-57 {
    width: calc(1094.4px * var(--scale));
  }
  .width-58 {
    width: calc(1113.6px * var(--scale));
  }
  .width-59 {
    width: calc(1132.8px * var(--scale));
  }
  .width-60 {
    width: calc(1152px * var(--scale));
  }
  .width-61 {
    width: calc(1171.2px * var(--scale));
  }
  .width-62 {
    width: calc(1190.4px * var(--scale));
  }
  .width-63 {
    width: calc(1209.6px * var(--scale));
  }
  .width-64 {
    width: calc(1228.8px * var(--scale));
  }
  .width-65 {
    width: calc(1248px * var(--scale));
  }
  .width-66 {
    width: calc(1267.2px * var(--scale));
  }
  .width-67 {
    width: calc(1286.4px * var(--scale));
  }
  .width-68 {
    width: calc(1305.6px * var(--scale));
  }
  .width-69 {
    width: calc(1324.8px * var(--scale));
  }
  .width-70 {
    width: calc(1344px * var(--scale));
  }
  .width-71 {
    width: calc(1363.2px * var(--scale));
  }
  .width-72 {
    width: calc(1382.4px * var(--scale));
  }
  .width-73 {
    width: calc(1401.6px * var(--scale));
  }
  .width-74 {
    width: calc(1420.8px * var(--scale));
  }
  .width-75 {
    width: calc(1440px * var(--scale));
  }
  .width-76 {
    width: calc(1459.2px * var(--scale));
  }
  .width-77 {
    width: calc(1478.4px * var(--scale));
  }
  .width-78 {
    width: calc(1497.6px * var(--scale));
  }
  .width-79 {
    width: calc(1516.8px * var(--scale));
  }
  .width-80 {
    width: calc(1536px * var(--scale));
  }
  .width-81 {
    width: calc(1555.2px * var(--scale));
  }
  .width-82 {
    width: calc(1574.4px * var(--scale));
  }
  .width-83 {
    width: calc(1593.6px * var(--scale));
  }
  .width-84 {
    width: calc(1612.8px * var(--scale));
  }
  .width-85 {
    width: calc(1632px * var(--scale));
  }
  .width-86 {
    width: calc(1651.2px * var(--scale));
  }
  .width-87 {
    width: calc(1670.4px * var(--scale));
  }
  .width-88 {
    width: calc(1689.6px * var(--scale));
  }
  .width-89 {
    width: calc(1708.8px * var(--scale));
  }
  .width-90 {
    width: calc(1728px * var(--scale));
  }
  .width-91 {
    width: calc(1747.2px * var(--scale));
  }
  .width-92 {
    width: calc(1766.4px * var(--scale));
  }
  .width-93 {
    width: calc(1785.6px * var(--scale));
  }
  .width-94 {
    width: calc(1804.8px * var(--scale));
  }
  .width-95 {
    width: calc(1824px * var(--scale));
  }
  .width-96 {
    width: calc(1843.2px * var(--scale));
  }
  .width-97 {
    width: calc(1862.4px * var(--scale));
  }
  .width-98 {
    width: calc(1881.6px * var(--scale));
  }
  .width-99 {
    width: calc(1900.8px * var(--scale));
  }
  .width-100 {
    width: calc(1920px * var(--scale));
  }
  
  
  .width-1-p { width: 1% }
  .width-2-p { width: 2% }
  .width-3-p { width: 3% }
  .width-4-p { width: 4% }
  .width-5-p { width: 5% }
  .width-6-p { width: 6% }
  .width-7-p { width: 7% }
  .width-8-p { width: 8% }
  .width-9-p { width: 9% }
  .width-10-p { width: 10% }
  .width-11-p { width: 11% }
  .width-12-p { width: 12% }
  .width-13-p { width: 13% }
  .width-14-p { width: 14% }
  .width-15-p { width: 15% }
  .width-16-p { width: 16% }
  .width-17-p { width: 17% }
  .width-18-p { width: 18% }
  .width-19-p { width: 19% }
  .width-20-p { width: 20% }
  .width-21-p { width: 21% }
  .width-22-p { width: 22% }
  .width-23-p { width: 23% }
  .width-24-p { width: 24% }
  .width-25-p { width: 25% }
  .width-26-p { width: 26% }
  .width-27-p { width: 27% }
  .width-28-p { width: 28% }
  .width-29-p { width: 29% }
  .width-30-p { width: 30% }
  .width-31-p { width: 31% }
  .width-32-p { width: 32% }
  .width-33-p { width: 33% }
  .width-34-p { width: 34% }
  .width-35-p { width: 35% }
  .width-36-p { width: 36% }
  .width-37-p { width: 37% }
  .width-38-p { width: 38% }
  .width-39-p { width: 39% }
  .width-40-p { width: 40% }
  .width-41-p { width: 41% }
  .width-42-p { width: 42% }
  .width-43-p { width: 43% }
  .width-44-p { width: 44% }
  .width-45-p { width: 45% }
  .width-46-p { width: 46% }
  .width-47-p { width: 47% }
  .width-48-p { width: 48% }
  .width-49-p { width: 49% }
  .width-50-p { width: 50% }
  .width-51-p { width: 51% }
  .width-52-p { width: 52% }
  .width-53-p { width: 53% }
  .width-54-p { width: 54% }
  .width-55-p { width: 55% }
  .width-56-p { width: 56% }
  .width-57-p { width: 57% }
  .width-58-p { width: 58% }
  .width-59-p { width: 59% }
  .width-60-p { width: 60% }
  .width-61-p { width: 61% }
  .width-62-p { width: 62% }
  .width-63-p { width: 63% }
  .width-64-p { width: 64% }
  .width-65-p { width: 65% }
  .width-66-p { width: 66% }
  .width-67-p { width: 67% }
  .width-68-p { width: 68% }
  .width-69-p { width: 69% }
  .width-70-p { width: 70% }
  .width-71-p { width: 71% }
  .width-72-p { width: 72% }
  .width-73-p { width: 73% }
  .width-74-p { width: 74% }
  .width-75-p { width: 75% }
  .width-76-p { width: 76% }
  .width-77-p { width: 77% }
  .width-78-p { width: 78% }
  .width-79-p { width: 79% }
  .width-80-p { width: 80% }
  .width-81-p { width: 81% }
  .width-82-p { width: 82% }
  .width-83-p { width: 83% }
  .width-84-p { width: 84% }
  .width-85-p { width: 85% }
  .width-86-p { width: 86% }
  .width-87-p { width: 87% }
  .width-88-p { width: 88% }
  .width-89-p { width: 89% }
  .width-90-p { width: 90% }
  .width-91-p { width: 91% }
  .width-92-p { width: 92% }
  .width-93-p { width: 93% }
  .width-94-p { width: 94% }
  .width-95-p { width: 95% }
  .width-96-p { width: 96% }
  .width-97-p { width: 97% }
  .width-98-p { width: 98% }
  .width-99-p { width: 99% }
  .width-100-p { width: 100% }
  
  .width-max {
    width: 100vw;
  }
  
  .width-auto {
    width: 100%;
  }
  
  .width-disable {
    width: 0%;
  }
  
  @mixin setSize($size) {
    height: $size;
    width: $size;
  }
  
  @media only screen and (max-width: 600px) {
    .height-mobile-1 {
      height: calc(8.12px * var(--scaleHeightMobile));
    }
    .height-mobile-2 {
      height: calc(16.24px * var(--scaleHeightMobile));
    }
    .height-mobile-3 {
      height: calc(24.36px * var(--scaleHeightMobile));
    }
    .height-mobile-4 {
      height: calc(32.48px * var(--scaleHeightMobile));
    }
    .height-mobile-5 {
      height: calc(40.6px * var(--scaleHeightMobile));
    }
    .height-mobile-6 {
      height: calc(48.72px * var(--scaleHeightMobile));
    }
    .height-mobile-7 {
      height: calc(56.84px * var(--scaleHeightMobile));
    }
    .height-mobile-8 {
      height: calc(64.96px * var(--scaleHeightMobile));
    }
    .height-mobile-9 {
      height: calc(73.08px * var(--scaleHeightMobile));
    }
    .height-mobile-10 {
      height: calc(81.2px * var(--scaleHeightMobile));
    }
    .height-mobile-11 {
      height: calc(89.32px * var(--scaleHeightMobile));
    }
    .height-mobile-12 {
      height: calc(97.44px * var(--scaleHeightMobile));
    }
    .height-mobile-13 {
      height: calc(105.56px * var(--scaleHeightMobile));
    }
    .height-mobile-14 {
      height: calc(113.68px * var(--scaleHeightMobile));
    }
    .height-mobile-15 {
      height: calc(121.8px * var(--scaleHeightMobile));
    }
    .height-mobile-16 {
      height: calc(129.92px * var(--scaleHeightMobile));
    }
    .height-mobile-17 {
      height: calc(138.04px * var(--scaleHeightMobile));
    }
    .height-mobile-18 {
      height: calc(146.16px * var(--scaleHeightMobile));
    }
    .height-mobile-19 {
      height: calc(154.28px * var(--scaleHeightMobile));
    }
    .height-mobile-20 {
      height: calc(162.4px * var(--scaleHeightMobile));
    }
    .height-mobile-21 {
      height: calc(170.52px * var(--scaleHeightMobile));
    }
    .height-mobile-22 {
      height: calc(178.64px * var(--scaleHeightMobile));
    }
    .height-mobile-23 {
      height: calc(186.76px * var(--scaleHeightMobile));
    }
    .height-mobile-24 {
      height: calc(194.88px * var(--scaleHeightMobile));
    }
    .height-mobile-25 {
      height: calc(203px * var(--scaleHeightMobile));
    }
    .height-mobile-26 {
      height: calc(211.12px * var(--scaleHeightMobile));
    }
    .height-mobile-27 {
      height: calc(219.24px * var(--scaleHeightMobile));
    }
    .height-mobile-28 {
      height: calc(227.36px * var(--scaleHeightMobile));
    }
    .height-mobile-29 {
      height: calc(235.48px * var(--scaleHeightMobile));
    }
    .height-mobile-30 {
      height: calc(243.6px * var(--scaleHeightMobile));
    }
    .height-mobile-31 {
      height: calc(251.72px * var(--scaleHeightMobile));
    }
    .height-mobile-32 {
      height: calc(259.84px * var(--scaleHeightMobile));
    }
    .height-mobile-33 {
      height: calc(267.96px * var(--scaleHeightMobile));
    }
    .height-mobile-34 {
      height: calc(276.08px * var(--scaleHeightMobile));
    }
    .height-mobile-35 {
      height: calc(284.2px * var(--scaleHeightMobile));
    }
    .height-mobile-36 {
      height: calc(292.32px * var(--scaleHeightMobile));
    }
    .height-mobile-37 {
      height: calc(300.44px * var(--scaleHeightMobile));
    }
    .height-mobile-38 {
      height: calc(308.56px * var(--scaleHeightMobile));
    }
    .height-mobile-39 {
      height: calc(316.68px * var(--scaleHeightMobile));
    }
    .height-mobile-40 {
      height: calc(324.8px * var(--scaleHeightMobile));
    }
    .height-mobile-41 {
      height: calc(332.92px * var(--scaleHeightMobile));
    }
    .height-mobile-42 {
      height: calc(341.04px * var(--scaleHeightMobile));
    }
    .height-mobile-43 {
      height: calc(349.16px * var(--scaleHeightMobile));
    }
    .height-mobile-44 {
      height: calc(357.28px * var(--scaleHeightMobile));
    }
    .height-mobile-45 {
      height: calc(365.4px * var(--scaleHeightMobile));
    }
    .height-mobile-46 {
      height: calc(373.52px * var(--scaleHeightMobile));
    }
    .height-mobile-47 {
      height: calc(381.64px * var(--scaleHeightMobile));
    }
    .height-mobile-48 {
      height: calc(389.76px * var(--scaleHeightMobile));
    }
    .height-mobile-49 {
      height: calc(397.88px * var(--scaleHeightMobile));
    }
    .height-mobile-50 {
      height: calc(406px * var(--scaleHeightMobile));
    }
    .height-mobile-51 {
      height: calc(414.12px * var(--scaleHeightMobile));
    }
    .height-mobile-52 {
      height: calc(422.24px * var(--scaleHeightMobile));
    }
    .height-mobile-53 {
      height: calc(430.36px * var(--scaleHeightMobile));
    }
    .height-mobile-54 {
      height: calc(438.48px * var(--scaleHeightMobile));
    }
    .height-mobile-55 {
      height: calc(446.6px * var(--scaleHeightMobile));
    }
    .height-mobile-56 {
      height: calc(454.72px * var(--scaleHeightMobile));
    }
    .height-mobile-57 {
      height: calc(462.84px * var(--scaleHeightMobile));
    }
    .height-mobile-58 {
      height: calc(470.96px * var(--scaleHeightMobile));
    }
    .height-mobile-59 {
      height: calc(479.08px * var(--scaleHeightMobile));
    }
    .height-mobile-60 {
      height: calc(487.2px * var(--scaleHeightMobile));
    }
    .height-mobile-61 {
      height: calc(495.32px * var(--scaleHeightMobile));
    }
    .height-mobile-62 {
      height: calc(503.44px * var(--scaleHeightMobile));
    }
    .height-mobile-63 {
      height: calc(511.56px * var(--scaleHeightMobile));
    }
    .height-mobile-64 {
      height: calc(519.68px * var(--scaleHeightMobile));
    }
    .height-mobile-65 {
      height: calc(527.8px * var(--scaleHeightMobile));
    }
    .height-mobile-66 {
      height: calc(535.92px * var(--scaleHeightMobile));
    }
    .height-mobile-67 {
      height: calc(544.04px * var(--scaleHeightMobile));
    }
    .height-mobile-68 {
      height: calc(552.16px * var(--scaleHeightMobile));
    }
    .height-mobile-69 {
      height: calc(560.28px * var(--scaleHeightMobile));
    }
    .height-mobile-70 {
      height: calc(568.4px * var(--scaleHeightMobile));
    }
    .height-mobile-71 {
      height: calc(576.52px * var(--scaleHeightMobile));
    }
    .height-mobile-72 {
      height: calc(584.64px * var(--scaleHeightMobile));
    }
    .height-mobile-73 {
      height: calc(592.76px * var(--scaleHeightMobile));
    }
    .height-mobile-74 {
      height: calc(600.88px * var(--scaleHeightMobile));
    }
    .height-mobile-75 {
      height: calc(609px * var(--scaleHeightMobile));
    }
    .height-mobile-76 {
      height: calc(617.12px * var(--scaleHeightMobile));
    }
    .height-mobile-77 {
      height: calc(625.24px * var(--scaleHeightMobile));
    }
    .height-mobile-78 {
      height: calc(633.36px * var(--scaleHeightMobile));
    }
    .height-mobile-79 {
      height: calc(641.48px * var(--scaleHeightMobile));
    }
    .height-mobile-80 {
      height: calc(649.6px * var(--scaleHeightMobile));
    }
    .height-mobile-81 {
      height: calc(657.72px * var(--scaleHeightMobile));
    }
    .height-mobile-82 {
      height: calc(665.84px * var(--scaleHeightMobile));
    }
    .height-mobile-83 {
      height: calc(673.96px * var(--scaleHeightMobile));
    }
    .height-mobile-84 {
      height: calc(682.08px * var(--scaleHeightMobile));
    }
    .height-mobile-85 {
      height: calc(690.2px * var(--scaleHeightMobile));
    }
    .height-mobile-86 {
      height: calc(698.32px * var(--scaleHeightMobile));
    }
    .height-mobile-87 {
      height: calc(706.44px * var(--scaleHeightMobile));
    }
    .height-mobile-88 {
      height: calc(714.56px * var(--scaleHeightMobile));
    }
    .height-mobile-89 {
      height: calc(722.68px * var(--scaleHeightMobile));
    }
    .height-mobile-90 {
      height: calc(730.8px * var(--scaleHeightMobile));
    }
    .height-mobile-91 {
      height: calc(738.92px * var(--scaleHeightMobile));
    }
    .height-mobile-92 {
      height: calc(747.04px * var(--scaleHeightMobile));
    }
    .height-mobile-93 {
      height: calc(755.16px * var(--scaleHeightMobile));
    }
    .height-mobile-94 {
      height: calc(763.28px * var(--scaleHeightMobile));
    }
    .height-mobile-95 {
      height: calc(771.4px * var(--scaleHeightMobile));
    }
    .height-mobile-96 {
      height: calc(779.52px * var(--scaleHeightMobile));
    }
    .height-mobile-97 {
      height: calc(787.64px * var(--scaleHeightMobile));
    }
    .height-mobile-98 {
      height: calc(795.76px * var(--scaleHeightMobile));
    }
    .height-mobile-99 {
      height: calc(803.88px * var(--scaleHeightMobile));
    }
    .height-mobile-100 {
      height: calc(812px * var(--scaleHeightMobile));
    }
    .height-mobile-auto {
      height: 100%;
    }
  
    .width-mobile-1 {
      width: calc(3.75px * var(--scaleMobile));
    }
    .width-mobile-2 {
      width: calc(7.5px * var(--scaleMobile));
    }
    .width-mobile-3 {
      width: calc(11.25px * var(--scaleMobile));
    }
    .width-mobile-4 {
      width: calc(15px * var(--scaleMobile));
    }
    .width-mobile-5 {
      width: calc(18.75px * var(--scaleMobile));
    }
    .width-mobile-6 {
      width: calc(22.5px * var(--scaleMobile));
    }
    .width-mobile-7 {
      width: calc(26.25px * var(--scaleMobile));
    }
    .width-mobile-8 {
      width: calc(30px * var(--scaleMobile));
    }
    .width-mobile-9 {
      width: calc(33.75px * var(--scaleMobile));
    }
    .width-mobile-10 {
      width: calc(37.5px * var(--scaleMobile));
    }
    .width-mobile-11 {
      width: calc(41.25px * var(--scaleMobile));
    }
    .width-mobile-12 {
      width: calc(45px * var(--scaleMobile));
    }
    .width-mobile-13 {
      width: calc(48.75px * var(--scaleMobile));
    }
    .width-mobile-14 {
      width: calc(52.5px * var(--scaleMobile));
    }
    .width-mobile-15 {
      width: calc(56.25px * var(--scaleMobile));
    }
    .width-mobile-16 {
      width: calc(60px * var(--scaleMobile));
    }
    .width-mobile-17 {
      width: calc(63.75px * var(--scaleMobile));
    }
    .width-mobile-18 {
      width: calc(67.5px * var(--scaleMobile));
    }
    .width-mobile-19 {
      width: calc(71.25px * var(--scaleMobile));
    }
    .width-mobile-20 {
      width: calc(75px * var(--scaleMobile));
    }
    .width-mobile-21 {
      width: calc(78.75px * var(--scaleMobile));
    }
    .width-mobile-22 {
      width: calc(82.5px * var(--scaleMobile));
    }
    .width-mobile-23 {
      width: calc(86.25px * var(--scaleMobile));
    }
    .width-mobile-24 {
      width: calc(90px * var(--scaleMobile));
    }
    .width-mobile-25 {
      width: calc(93.75px * var(--scaleMobile));
    }
    .width-mobile-26 {
      width: calc(97.5px * var(--scaleMobile));
    }
    .width-mobile-27 {
      width: calc(101.25px * var(--scaleMobile));
    }
    .width-mobile-28 {
      width: calc(105px * var(--scaleMobile));
    }
    .width-mobile-29 {
      width: calc(108.75px * var(--scaleMobile));
    }
    .width-mobile-30 {
      width: calc(112.5px * var(--scaleMobile));
    }
    .width-mobile-31 {
      width: calc(116.25px * var(--scaleMobile));
    }
    .width-mobile-32 {
      width: calc(120px * var(--scaleMobile));
    }
    .width-mobile-33 {
      width: calc(123.75px * var(--scaleMobile));
    }
    .width-mobile-34 {
      width: calc(127.5px * var(--scaleMobile));
    }
    .width-mobile-35 {
      width: calc(131.25px * var(--scaleMobile));
    }
    .width-mobile-36 {
      width: calc(135px * var(--scaleMobile));
    }
    .width-mobile-37 {
      width: calc(138.75px * var(--scaleMobile));
    }
    .width-mobile-38 {
      width: calc(142.5px * var(--scaleMobile));
    }
    .width-mobile-39 {
      width: calc(146.25px * var(--scaleMobile));
    }
    .width-mobile-40 {
      width: calc(150px * var(--scaleMobile));
    }
    .width-mobile-41 {
      width: calc(153.75px * var(--scaleMobile));
    }
    .width-mobile-42 {
      width: calc(157.5px * var(--scaleMobile));
    }
    .width-mobile-43 {
      width: calc(161.25px * var(--scaleMobile));
    }
    .width-mobile-44 {
      width: calc(165px * var(--scaleMobile));
    }
    .width-mobile-45 {
      width: calc(168.75px * var(--scaleMobile));
    }
    .width-mobile-46 {
      width: calc(172.5px * var(--scaleMobile));
    }
    .width-mobile-47 {
      width: calc(176.25px * var(--scaleMobile));
    }
    .width-mobile-48 {
      width: calc(180px * var(--scaleMobile));
    }
    .width-mobile-49 {
      width: calc(183.75px * var(--scaleMobile));
    }
    .width-mobile-50 {
      width: calc(187.5px * var(--scaleMobile));
    }
    .width-mobile-51 {
      width: calc(191.25px * var(--scaleMobile));
    }
    .width-mobile-52 {
      width: calc(195px * var(--scaleMobile));
    }
    .width-mobile-53 {
      width: calc(198.75px * var(--scaleMobile));
    }
    .width-mobile-54 {
      width: calc(202.5px * var(--scaleMobile));
    }
    .width-mobile-55 {
      width: calc(206.25px * var(--scaleMobile));
    }
    .width-mobile-56 {
      width: calc(210px * var(--scaleMobile));
    }
    .width-mobile-57 {
      width: calc(213.75px * var(--scaleMobile));
    }
    .width-mobile-58 {
      width: calc(217.5px * var(--scaleMobile));
    }
    .width-mobile-59 {
      width: calc(221.25px * var(--scaleMobile));
    }
    .width-mobile-60 {
      width: calc(225px * var(--scaleMobile));
    }
    .width-mobile-61 {
      width: calc(228.75px * var(--scaleMobile));
    }
    .width-mobile-62 {
      width: calc(232.5px * var(--scaleMobile));
    }
    .width-mobile-63 {
      width: calc(236.25px * var(--scaleMobile));
    }
    .width-mobile-64 {
      width: calc(240px * var(--scaleMobile));
    }
    .width-mobile-65 {
      width: calc(243.75px * var(--scaleMobile));
    }
    .width-mobile-66 {
      width: calc(247.5px * var(--scaleMobile));
    }
    .width-mobile-67 {
      width: calc(251.25px * var(--scaleMobile));
    }
    .width-mobile-68 {
      width: calc(255px * var(--scaleMobile));
    }
    .width-mobile-69 {
      width: calc(258.75px * var(--scaleMobile));
    }
    .width-mobile-70 {
      width: calc(262.5px * var(--scaleMobile));
    }
    .width-mobile-71 {
      width: calc(266.25px * var(--scaleMobile));
    }
    .width-mobile-72 {
      width: calc(270px * var(--scaleMobile));
    }
    .width-mobile-73 {
      width: calc(273.75px * var(--scaleMobile));
    }
    .width-mobile-74 {
      width: calc(277.5px * var(--scaleMobile));
    }
    .width-mobile-75 {
      width: calc(281.25px * var(--scaleMobile));
    }
    .width-mobile-76 {
      width: calc(285px * var(--scaleMobile));
    }
    .width-mobile-77 {
      width: calc(288.75px * var(--scaleMobile));
    }
    .width-mobile-78 {
      width: calc(292.5px * var(--scaleMobile));
    }
    .width-mobile-79 {
      width: calc(296.25px * var(--scaleMobile));
    }
    .width-mobile-80 {
      width: calc(300px * var(--scaleMobile));
    }
    .width-mobile-81 {
      width: calc(303.75px * var(--scaleMobile));
    }
    .width-mobile-82 {
      width: calc(307.5px * var(--scaleMobile));
    }
    .width-mobile-83 {
      width: calc(311.25px * var(--scaleMobile));
    }
    .width-mobile-84 {
      width: calc(315px * var(--scaleMobile));
    }
    .width-mobile-85 {
      width: calc(318.75px * var(--scaleMobile));
    }
    .width-mobile-86 {
      width: calc(322.5px * var(--scaleMobile));
    }
    .width-mobile-87 {
      width: calc(326.25px * var(--scaleMobile));
    }
    .width-mobile-88 {
      width: calc(330px * var(--scaleMobile));
    }
    .width-mobile-89 {
      width: calc(333.75px * var(--scaleMobile));
    }
    .width-mobile-90 {
      width: calc(337.5px * var(--scaleMobile));
    }
    .width-mobile-91 {
      width: calc(341.25px * var(--scaleMobile));
    }
    .width-mobile-92 {
      width: calc(345px * var(--scaleMobile));
    }
    .width-mobile-93 {
      width: calc(348.75px * var(--scaleMobile));
    }
    .width-mobile-94 {
      width: calc(352.5px * var(--scaleMobile));
    }
    .width-mobile-95 {
      width: calc(356.25px * var(--scaleMobile));
    }
    .width-mobile-96 {
      width: calc(360px * var(--scaleMobile));
    }
    .width-mobile-97 {
      width: calc(363.75px * var(--scaleMobile));
    }
    .width-mobile-98 {
      width: calc(367.5px * var(--scaleMobile));
    }
    .width-mobile-99 {
      width: calc(371.25px * var(--scaleMobile));
    }
    .width-mobile-100 {
      width: calc(375px * var(--scaleMobile));
    }
    .width-mobile-auto {
      width: 100%;
    }
  }
  