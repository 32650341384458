// .active{
//     color: blue;
//     border-bottom: 1px blue solid;
// }
.hero-text-for-header-image{
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 0px 1px 2px #000000;
}

.header-logout-button{
    width: 115px;
    height: 34px;
    border-radius: 50px;
    font-weight: 600;
    line-height: 16.71px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    border: 1px solid white;
    outline: none;
    transition: 0.3s;
}

.header-logout-button:hover{
    background-color: white;
    opacity: 1;
    font-size: 14px;
    color: #000000;
    font-weight: 700;
}

@media only screen and (max-width: 960px)  {
    .header-logout-button{
        width: 100px;
        height: 30px;
        border-radius: 50px;
    }
}