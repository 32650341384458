
.Nav_link:link {
    color: #000000;
    /* font-size: 18px; */
    text-decoration: none;
    font-weight: 700;
  }
  .Nav_link:visited {
    color:#000000;
  }
  /* .Nav_link:hover {
    /* color: yellow; */
  /* } */ 
  .Nav_link:active {
    color: teal;
  }
  
  .activeRoute {
    color: black;
    border-bottom: 0.2rem solid rgb(255, 255, 255);
    cursor: pointer;
  }

  .header-image{
    background-image: url('https://www.islamiclandmarks.com/wp-content/uploads/2016/01/Masjid-Quba-front.jpg');
    background-size: cover;
    height: 395px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .navbar-background{

    background-color: #FAE2B3;
    padding: 15px 0%;
  }

  .header-logout-button-for-web{
    text-decoration: none;
    font-weight: 500;
    background: rgba(52, 52, 52, 0.7);
    color: white;
    padding: 3px 15px;
    border-radius: 6px;
    position: fixed;
    z-index: 2;
    left: 70px;
    top: 60px;
  }

  .header-logout-button-for-mobile{
    text-decoration: none;
    font-weight: 500;
    background: rgba(52, 52, 52, 0.7);
    color: white;
    padding: 3px 15px;
    border-radius: 6px;
    position: fixed;
    z-index: 2;
    left: 15px;
    top: 60px;
  }
