.input-style{
    width: 100%;
    height: 140%;
    border: none;
    box-shadow: 0px 0px 3px #a39d9d;
    outline: none;
    padding-left: 8px;
    font-size: 14px;
    border-radius: 2px;
}

.box{
     box-shadow: 0px 0px 3px #a39d9d;
}

.option:hover{
    background-color: darkgreen;
}

.option{
    background-color: white;
}


