.add-masjid-page{
    &-map{
    border: 1px solid #cfc7c752;
    border-radius: 8px;
    box-shadow: 0px 0px 9px #cfc7c752;
    }
    &-no-scroll{
        height: 0px
    }
    &-scroll{
        height: 350px;
        overflow-y: scroll;
        padding: 2%;
    }
    &-sign-in-button{
        margin: 2px !important;
        background-color: white;
        border: 1px solid #0000002b !important; 
        width: 300px;
        font-size: 0.875rem;
    }
    &-google-icon{
        position: absolute;
        left: 25px;
        display: flex;
        align-items: center;
    }
    &-close-pop-up-button{
        margin: 2px !important;
    }
}

@media screen and (max-width : "390px") {
    .add-masjid-page{
        &-sign-in-button{
            width : 230px;
            font-size: 12px !important;
        }
        &-google-icon{
            left: 15px;
        }
        &-close-pop-up-button{
            font-size: 12px !important;
        }
    }
}

@media screen and (max-width : "320px") {
    .add-masjid-page{
        &-sign-in-button{
            width : 200px;
            font-size: 10px !important;
        }
        &-google-icon{
            left: 10px;
        }
        &-close-pop-up-button{
            font-size: 10px !important;
        }
    }
}


