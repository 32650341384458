.font-color{
    &-black{
        color: #2C2C2C;
    }
    &-dark-black{
        color: #000000;
    }
    &-green{
        color: #1AA654;
    }
    &-grey{
        color: #00000080;
    }
    &-white{
        color: #FFFFFF;
    }
}
.background-color{
    &-light-brown{
        background-color: #FAE2B3;
    }
    &-dark-brown{
        background-color: #E1C794;
    }
    &-green{
        background-color: #27BD65;
    }
    &-grey{
        background-color: #DCDCDC;
    }
    &-dark-grey{
        background-color: #24212182;
    }
}