@mixin round-button-one($width, $color) {
    border-radius: 500px;
    border: solid 2px $color;
    background-color: rgba(0, 0, 0, 0);
    width: $width;
  }
  
  @mixin round-button-filled($width, $color) {
    border-radius: 500px;
    background: $color;
    width: $width;
    cursor: pointer;
  }
  
  @mixin square-button-flat($width, $color, $radius) {
    background: $color;
    border-radius: $radius;
    width: $width;
    cursor: pointer;
  }
  
  @mixin square-button-sharp($width, $color) {
    background-color: $color;
    width: $width;
    cursor: pointer;
  }
  
  @mixin square-button-transparent($width, $color) {
    border: solid 2px $color;
    background-color: rgba(0, 0, 0, 0);
    width: $width;
    cursor: pointer;
  }

  .button{
    &-green-color{
      background-color: #3c733a;
      border-color: #3c733a;
      color: white;
      border-radius: 5px;
    }
  }

.btn:focus{
  outline: none;
  box-shadow: none;
}
.btn:hover{
  font-weight: 600;
  color: white;
}

  .border{
    &-1{
      border-radius: 1px;
    }
    &-2{
      border-radius: 2px;
    }
    &-3{
      border-radius: 3px;
    }
    &-4{
      border-radius: 4px;
    }
    &-5{
      border-radius: 5px;
    }
    &-6{
      border-radius: 6px;
    }
    &-7{
      border-radius: 7px;
    }
    &-8{
      border-radius: 8px;
    }
    &-9{
      border-radius: 9px;
    }
    &-10{
      border-radius: 10px;
    }
    &-11{
      border-radius: 11px;
    }
    &-12{
      border-radius: 12px;
    }
    &-13{
      border-radius: 13px;
    }
    &-14{
      border-radius: 14px;
    }
    &-15{
      border-radius: 15px;
    }
    &-16{
      border-radius: 16px;
    }
    &-17{
      border-radius: 17px;
    }
    &-18{
      border-radius: 18px;
    }
    &-19{
      border-radius: 19px;
    }
    &-20{
      border-radius: 20px;
    }
    &-21{
      border-radius: 21px;
    }
    &-22{
      border-radius: 22px;
    }
    &-23{
      border-radius: 23px;
    }
    &-24{
      border-radius: 24px;
    }
    &-25{
      border-radius: 25px;
    }
  }
  