.dynamic-breakpoint-1 {
    max-width: calc(19.2px * var(--scale));
  }
  .dynamic-breakpoint-2 {
    max-width: calc(38.4px * var(--scale));
  }
  .dynamic-breakpoint-3 {
    max-width: calc(57.59px * var(--scale));
  }
  .dynamic-breakpoint-4 {
    max-width: calc(76.8px * var(--scale));
  }
  .dynamic-breakpoint-5 {
    max-width: calc(96px * var(--scale));
  }
  .dynamic-breakpoint-6 {
    max-width: calc(115.19px * var(--scale));
  }
  .dynamic-breakpoint-7 {
    max-width: calc(134.4px * var(--scale));
  }
  .dynamic-breakpoint-8 {
    max-width: calc(153.6px * var(--scale));
  }
  .dynamic-breakpoint-9 {
    max-width: calc(172.7px * var(--scale));
  }
  .dynamic-breakpoint-10 {
    max-width: calc(192px * var(--scale));
  }
  .dynamic-breakpoint-11 {
    max-width: calc(211.2px * var(--scale));
  }
  .dynamic-breakpoint-12 {
    max-width: calc(230.3px * var(--scale));
  }
  .dynamic-breakpoint-13 {
    max-width: calc(249.6px * var(--scale));
  }
  .dynamic-breakpoint-14 {
    max-width: calc(268.8px * var(--scale));
  }
  .dynamic-breakpoint-15 {
    max-width: calc(288px * var(--scale));
  }
  .dynamic-breakpoint-16 {
    max-width: calc(307.2px * var(--scale));
  }
  .dynamic-breakpoint-17 {
    max-width: calc(326.4px * var(--scale));
  }
  .dynamic-breakpoint-18 {
    max-width: calc(345.5px * var(--scale));
  }
  .dynamic-breakpoint-19 {
    max-width: calc(364.8px * var(--scale));
  }
  .dynamic-breakpoint-20 {
    max-width: calc(384px * var(--scale));
  }
  .dynamic-breakpoint-21 {
    max-width: calc(403.2px * var(--scale));
  }
  .dynamic-breakpoint-22 {
    max-width: calc(422.4px * var(--scale));
  }
  .dynamic-breakpoint-23 {
    max-width: calc(441.6px * var(--scale));
  }
  .dynamic-breakpoint-24 {
    max-width: calc(460.7px * var(--scale));
  }
  .dynamic-breakpoint-25 {
    max-width: calc(480px * var(--scale));
  }
  .dynamic-breakpoint-26 {
    max-width: calc(499.2px * var(--scale));
  }
  .dynamic-breakpoint-27 {
    max-width: calc(518.4px * var(--scale));
  }
  .dynamic-breakpoint-28 {
    max-width: calc(537.6px * var(--scale));
  }
  .dynamic-breakpoint-29 {
    max-width: calc(556.8px * var(--scale));
  }
  .dynamic-breakpoint-30 {
    max-width: calc(576px * var(--scale));
  }
  .dynamic-breakpoint-31 {
    max-width: calc(595.2px * var(--scale));
  }
  .dynamic-breakpoint-32 {
    max-width: calc(614.4px * var(--scale));
  }
  .dynamic-breakpoint-33 {
    max-width: calc(633.6px * var(--scale));
  }
  .dynamic-breakpoint-34 {
    max-width: calc(652.8px * var(--scale));
  }
  .dynamic-breakpoint-35 {
    max-width: calc(672px * var(--scale));
  }
  .dynamic-breakpoint-36 {
    max-width: calc(691.19px * var(--scale));
  }
  .dynamic-breakpoint-37 {
    max-width: calc(710.4px * var(--scale));
  }
  .dynamic-breakpoint-38 {
    max-width: calc(729.6px * var(--scale));
  }
  .dynamic-breakpoint-39 {
    max-width: calc(748.8px * var(--scale));
  }
  .dynamic-breakpoint-40 {
    max-width: calc(768px * var(--scale));
  }
  .dynamic-breakpoint-41 {
    max-width: calc(787.1px * var(--scale));
  }
  .dynamic-breakpoint-42 {
    max-width: calc(806.4px * var(--scale));
  }
  .dynamic-breakpoint-43 {
    max-width: calc(825.6px * var(--scale));
  }
  .dynamic-breakpoint-44 {
    max-width: calc(844.8px * var(--scale));
  }
  .dynamic-breakpoint-45 {
    max-width: calc(864px * var(--scale));
  }
  .dynamic-breakpoint-46 {
    max-width: calc(883.2px * var(--scale));
  }
  .dynamic-breakpoint-47 {
    max-width: calc(902.4px * var(--scale));
  }
  .dynamic-breakpoint-48 {
    max-width: calc(921.5px * var(--scale));
  }
  .dynamic-breakpoint-49 {
    max-width: calc(940.8px * var(--scale));
  }
  .dynamic-breakpoint-50 {
    max-width: calc(960px * var(--scale));
  }
  .dynamic-breakpoint-51 {
    max-width: calc(979.2px * var(--scale));
  }
  .dynamic-breakpoint-52 {
    max-width: calc(998.4px * var(--scale));
  }
  .dynamic-breakpoint-53 {
    max-width: calc(1017.6px * var(--scale));
  }
  .dynamic-breakpoint-54 {
    max-width: calc(1036.8px * var(--scale));
  }
  .dynamic-breakpoint-55 {
    max-width: calc(1056px * var(--scale));
  }
  .dynamic-breakpoint-56 {
    max-width: calc(1075.2px * var(--scale));
  }
  .dynamic-breakpoint-57 {
    max-width: calc(1094.39px * var(--scale));
  }
  .dynamic-breakpoint-58 {
    max-width: calc(1113.6px * var(--scale));
  }
  .dynamic-breakpoint-59 {
    max-width: calc(1132.8px * var(--scale));
  }
  .dynamic-breakpoint-60 {
    max-width: calc(1152px * var(--scale));
  }
  .dynamic-breakpoint-61 {
    max-width: calc(1171.2px * var(--scale));
  }
  .dynamic-breakpoint-62 {
    max-width: calc(1190.4px * var(--scale));
  }
  .dynamic-breakpoint-63 {
    max-width: calc(1209.6px * var(--scale));
  }
  .dynamic-breakpoint-64 {
    max-width: calc(1228.8px * var(--scale));
  }
  .dynamic-breakpoint-65 {
    max-width: calc(1248px * var(--scale));
  }
  .dynamic-breakpoint-66 {
    max-width: calc(1267.2px * var(--scale));
  }
  .dynamic-breakpoint-67 {
    max-width: calc(1286.4px * var(--scale));
  }
  .dynamic-breakpoint-68 {
    max-width: calc(1305.6px * var(--scale));
  }
  .dynamic-breakpoint-69 {
    max-width: calc(1324.8px * var(--scale));
  }
  .dynamic-breakpoint-70 {
    max-width: calc(1344px * var(--scale));
  }
  .dynamic-breakpoint-71 {
    max-width: calc(1363.19px * var(--scale));
  }
  .dynamic-breakpoint-72 {
    max-width: calc(1382.39px * var(--scale));
  }
  .dynamic-breakpoint-73 {
    max-width: calc(1401.6px * var(--scale));
  }
  .dynamic-breakpoint-74 {
    max-width: calc(1420.8px * var(--scale));
  }
  .dynamic-breakpoint-75 {
    max-width: calc(1440px * var(--scale));
  }
  .dynamic-breakpoint-76 {
    max-width: calc(1459.2px * var(--scale));
  }
  .dynamic-breakpoint-77 {
    max-width: calc(1478.4px * var(--scale));
  }
  .dynamic-breakpoint-78 {
    max-width: calc(1497.6px * var(--scale));
  }
  .dynamic-breakpoint-79 {
    max-width: calc(1516.8px * var(--scale));
  }
  .dynamic-breakpoint-80 {
    max-width: calc(1536px * var(--scale));
  }
  .dynamic-breakpoint-81 {
    max-width: calc(1555.2px * var(--scale));
  }
  .dynamic-breakpoint-82 {
    max-width: calc(1574.39px * var(--scale));
  }
  .dynamic-breakpoint-83 {
    max-width: calc(1593.6px * var(--scale));
  }
  .dynamic-breakpoint-84 {
    max-width: calc(1612.8px * var(--scale));
  }
  .dynamic-breakpoint-85 {
    max-width: calc(1632px * var(--scale));
  }
  .dynamic-breakpoint-86 {
    max-width: calc(1651.2px * var(--scale));
  }
  .dynamic-breakpoint-87 {
    max-width: calc(1670.4px * var(--scale));
  }
  .dynamic-breakpoint-88 {
    max-width: calc(1689.6px * var(--scale));
  }
  .dynamic-breakpoint-89 {
    max-width: calc(1708.8px * var(--scale));
  }
  .dynamic-breakpoint-90 {
    max-width: calc(1728px * var(--scale));
  }
  .dynamic-breakpoint-91 {
    max-width: calc(1747.2px * var(--scale));
  }
  .dynamic-breakpoint-92 {
    max-width: calc(1766.4px * var(--scale));
  }
  .dynamic-breakpoint-93 {
    max-width: calc(1785.6px * var(--scale));
  }
  .dynamic-breakpoint-94 {
    max-width: calc(1804.8px * var(--scale));
  }
  .dynamic-breakpoint-95 {
    max-width: calc(1824px * var(--scale));
  }
  .dynamic-breakpoint-96 {
    max-width: calc(1843.19px * var(--scale));
  }
  .dynamic-breakpoint-97 {
    max-width: calc(1862.39px * var(--scale));
  }
  .dynamic-breakpoint-98 {
    max-width: calc(1881.6px * var(--scale));
  }
  .dynamic-breakpoint-99 {
    max-width: calc(1900.8px * var(--scale));
  }
  .dynamic-breakpoint-100 {
    max-width: calc(1920px * var(--scale));
  }
  
  @media only screen and (max-width: 600px) {
    .dynamic-breakpoint-override {
      max-width: 100%;
    }
  }
  