.background_linear_gradiant{
    // background-image: linear-gradient(to top, rgba(252, 249, 244, 0) 0%, #FEF2DF 100%);
    background-image: linear-gradient(to top, rgba(252, 249, 244, 0) 21%, #ffcf6f 166%)
}
.static-image{
    background-image: url('../../assets/images/background_pattern.png');
    background-size: cover;
    background-position: center;
}

.static-header-logout{
    position: absolute;
    right: 50px;
    top: 25px;
    z-index: 4;
    width: 115px;
    height: 34px;
    border-radius: 50px;
    font-weight: 700;
    line-height: 16.71px;
    cursor: pointer;
    font-size: 14px;
    color: black;
    background-color: #e1c79400;
    border: 1px solid #9a8356;
    outline: none;
    // opacity: 0.8;
    transition: 0.3s;
}

.static-header-logout:hover{
    background-color: #e1c794;
    opacity: 1;
    font-size: 14px;
}

@media only screen and (max-width: 960px)  {
    .static-header-logout{
        position: absolute;
        right: 8px;
        top: 5px;
        width: 100px;
        height: 30px;
        font-size: 13px;
    }
}

.tab-header-span{
    border-bottom: 4px solid white;
    width: 30px;
    margin-top: 3%;
    margin-left: 38%;
    border-radius: 5px;
}

.inactive{
    background-color: #FAE2B3;
    border: 1px solid #b0996d;
    color: black;
    border-top: none;
    width: 215px;
    height: 51px; 
    border-radius: 12px;
    line-height: 23.87px;
    font-size: 15px; 
    font-style: normal;
    font-weight: 400;
    transition-duration: 0.5s;
}
.active{
    background-color: #E1C794;
    border: 1px solid #9a8356;
    color: black;
    width: 231px;
    height: 64px;
    border-radius: 12px; 
    font-weight: 700;
    z-index: 2;
    font-size: 16px; 
    font-weight: 700;
    transition-duration: 0.5s;
    position: absolute;
}

.active-tab{
    font-size: 16px; 
    font-weight: 700;
    color: black;

}

@media only screen and (max-width: 767px) and (min-width: 601px) {
    .inactive{
        width: 160px;
        height: 43px; 
    }
    .active{
        color: black;
        width: 170px;
        height: 53px;
    }
    .active-tab{
        font-size: 16px; 
        font-weight: 700;
        color: black;
    }
   }

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .inactive{
        width: 180px;
        height: 43px; 
    }
    .active{
        width: 190px;
        height: 53px;
    }
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 10%;
    margin-top: 8px;
    height: 4px;
    background: #F26640;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 5px;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: #FDBF50;
}

.slider-mobile {
    -webkit-appearance: none;
    appearance: none;
    margin-top: 8px;
    height: 3px;
    background: #F26640;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    border-radius: 5px;
    width: 100px;
}

.slider-mobile::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 13px;
    height: 13px;
    border-radius: 15px;
    background: #FDBF50;
}