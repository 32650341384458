.about-us{
    &-prayer-input{
        display: block;
        width: 77%;
        padding: 0.375rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ffffff;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        text-align: end !important;
        outline: none;
        min-height: 38px;
        padding-right: 30px;
    }
    &-prayer-input-enable{
        display: block;
        width: 77%;
        padding: 0.375rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #BFBFBF;
        border-radius: 20px;
        box-shadow: 1px 1px 3px #bfbfbf;
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        text-align: center !important;
        outline: none;
        min-height: 38px;
        cursor: text;
    }
    &-share-button-div{
        position: fixed;
        z-index: 5;
        padding: 19px 0px;
        right: 0px;
        width: 100%;
        overflow: auto;
        background-color: #7b7b7ba6;
        justify-content: center;
        display: flex;
        bottom: 0;
    }
    &-icon-background{
    background: #cbcbca;
    border-radius: 25px;
    padding: 3px;
    }
}