:root {
    --gray: #34495e;
    --darkgray: #2c3e50;
  }
  
  select {
    /* Reset Select */
    appearance: none;
    outline: 0;
    // border: 1px border;
    box-shadow: none;
    flex: 1;
    padding: 0 1em;
    color: black;
    background-color: #ffffff;
    background-image: none;
    cursor: pointer;
  }
  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }
  /* Custom Select wrapper */
  .select {
    position: relative;
    display: flex;
    width: 20em;
    height: 3em;
    border-radius: .25em;
    overflow: hidden;
  }
  /* Arrow */
  .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    background-color: #34495e;
    transition: .25s all ease;
    pointer-events: none;
  }
  /* Transition */
  .select:hover::after {
    color: #f39c12;
  }
  
  /* Other styles*/
