.round-plus-icon{
    &-for-mobile{
        border: 1px solid #376935;
        border-radius: 68px;
        padding-bottom: 3.5%;
        width: 110px;
        font-size: 77px;
        color: #376935;
    }
    &-for-web{
        border: 1px solid #376935;
        border-radius: 68px;
        padding-bottom: 1.5%;
        width: 110px;
        font-size: 77px;
        color: #376935;
    }
}

.eventList{
  &-add-event-button{
    position: fixed;
    bottom: 100px;
    right: 30px;
    font-weight: 500;
    z-index: 1;
    cursor: pointer
  }
  &-add-event-button-for-mobile{
      position: fixed;
      bottom: 30px;
      right: 20px;
      font-weight: 500;
      z-index: 1;
      cursor: pointer
  }
  &-input-feild{
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529b8;
    background-color: #FCFDFF;
    background-clip: padding-box;
    border: 1px solid #F0F2F5;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 16px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    min-height: 62px;
    outline: none;
    align-items: center;
    display: flex;
  }
  &-input-feild:focus{
    border: 1px solid rgba(85, 80, 69, 0.385);
    box-shadow: 1px 1px 10px rgba(85, 80, 69, 0.487);
  }
}
.eventDetail{
  &-top-box{
      background: #FCFDFF;
      border-radius: 14px;
      border: 1px solid #F0F2F5;
      min-height: 62px;
  }
}
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  /* The Close Button */
  .close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }
  
  .modal-body {padding: 2px 16px;}
  
  .modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }