  .flex {
      display: flex;
    }
  
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  
  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .flex-shrink-disable {
    flex-shrink: 0;
  }
  
  .flex-grow-disable {
    flex-grow: 0;
  }

  .grid-center{
    display: grid;
    justify-content: center;
  }
  
  .horizontal-center {
    display: flex;
    justify-content: center;
  }
  
  .horizontal-start {
    display: flex;
    justify-content: flex-start;
  }
  
  .horizontal-end {
    display: flex;
    justify-content: flex-end;
  }
  
  .horizontal-between {
    display: flex;
    justify-content: space-between;
  }
  
  .horizontal-even {
    display: flex;
    justify-content: space-evenly;
  }
  
  .horizontal-around {
    display: flex;
    justify-content: space-around;
  }
  
  .vertical-center {
    display: flex;
    align-items: center;
  }
  
  .vertical-start {
    display: flex;
    align-items: flex-start;
  }
  
  .vertical-end {
    display: flex;
    align-items: flex-end;
  }
  
  .center-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .relative {
    position: relative;
  }
  
  .absolute {
    position: absolute;
  
    &-origin {
      @extend .absolute;
      left: 0%;
      top: 0%;
    }
  }
  
  .set-image {
    background-repeat: no-repeat;
    background-position: center;
  
    &--contain {
      @extend .set-image;
      background-size: contain;
    }
  
    &--cover {
      @extend .set-image;
      background-size: cover;
    }
  }
  
  .object-fit {
    &-contain {
      object-fit: contain;
    }
    &-cover {
      object-fit: cover;
    }
    &-fill {
      object-fit: fill;
    }
  }
  
  .full-container {
    height: 100vh;
    width: 100vw;
  }
  
  .overflow {
    &-hidden {
      overflow: hidden;
    }
    &-x-scroll {
      overflow-x: scroll;
    }
    &-y-scroll {
      overflow-y: scroll;
    }
    &-x-auto {
      overflow-x: auto;
    }
    &-y-auto {
      overflow-y: auto;
    }
  }
  
  .float-item {
    &-right{
      float: right;
    }
  
    &-left {
      float: left;
    }
  }
  
  .opacity {
    &-0 {
      opacity: 0;
    }
  
    &-5 {
      opacity: 0.05;
    }
  
    &-10 {
      opacity: 0.1;
    }
  
    &-15 {
      opacity: 0.15;
    }
  
    &-20 {
      opacity: 0.2;
    }
  
    &-25 {
      opacity: 0.25;
    }
  
    &-30 {
      opacity: 0.3;
    }
  
    &-35 {
      opacity: 0.35;
    }
  
    &-40 {
      opacity: 0.4;
    }
  
    &-45 {
      opacity: 0.45;
    }
  
    &-50 {
      opacity: 0.5;
    }
  
    &-55 {
      opacity: 0.55;
    }
  
    &-60 {
      opacity: 0.6;
    }
  
    &-65 {
      opacity: 0.65;
    }
  
    &-70 {
      opacity: 0.7;
    }
  
    &-75 {
      opacity: 0.75;
    }
  
    &-80 {
      opacity: 0.8;
    }
  
    &-85 {
      opacity: 0.85;
    }
  
    &-90 {
      opacity: 0.9;
    }
  
    &-95 {
      opacity: 0.95;
    }
  
    &-100 {
      opacity: 1;
    }
  }

  .space{
    &-no-wrap{
      white-space: nowrap;
    }
  }

.text-shadow-grey{
  text-shadow: 0 2.5px 3.75px #0000004a;
}

.div-border {
    border: 1px solid #BFBFBF;
    border-radius: 20px;
    box-shadow: 1px 1px 3px #bfbfbf;
}

.modal {
  display: block;
  position: fixed;
  z-index: 5;
  padding: 100px 0px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.6);
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  top: 30%;
}

.form-control-input-with-icon{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  border: 1.4px solid #F0F2F5;
  border-right: none;
  outline: none;
  height: 62px;
  box-shadow: 0px 0px 8px 0px #dcdcdc;
  border-top-left-radius: 10.29px;
  border-bottom-left-radius: 10.29px;
}

.search-input-clear-button{
  height: 62px;
  border: 1.4px solid #F0F2F5;
  border-left: none;
  border-top-right-radius: 10.29px;
  border-bottom-right-radius: 10.29px;
  background-color: white;
  box-shadow: 8px 0px 8px 0px #dcdcdc;
  margin-left: -1%;
  z-index: 2;
  outline: none;
}

  .clear-fontawesome{
    margin: 15px 25px 15px 15px;
    font-size: 20px;
  }
  .search-input-search-button{
    border: 1.4px solid #F0F2F5;
    border-top-right-radius: 10.29px;
    border-bottom-right-radius: 10.29px;
    height: 62px;
    border-left: none;
    margin-left: -1%;
    z-index: 1;
    background-color: #DCDCDC;
    box-shadow: 0px 0px 8px 0px #dcdcdc;
    outline: none;
  }

  .search-fontawesome{
    margin: 15px 15px 15px 28px;
    font-size: 20px;
    color: black;
  }

  .clear-fontawesome-for-mobile{
    margin: 10px 8px 10px 8px;
    font-size: 20px;
  }

  .search-fontawesome-for-mobile{
    margin: 10px 8px 10px 12px;
    font-size: 20px;
    color: black;
  }

.pointer{
  cursor: pointer;
}

@media screen and (max-width : '600px'){
  .form-control-input-with-icon{
    height: 45px;
  }
  
  .search-input-clear-button{
    height: 45px;
  }

  .search-input-search-button{
    height: 45px;
  }
}