.masjid-list{
    &-page-bottom-bar{
        display: flex;
    }
    &-page-top-bar{
        display: flex;
    }
    &-follow-button{
        background-color:white;
        padding: 1px 10px;
        border-radius: 4px;
        border-color: #6aaaff;
        font-weight: 400;
        color: #6aaaff;
        font-size: 14px;
    }
    &-follow-button:hover{
        background-color:#6aaaff;
        padding: 1px 10px;
        border-radius: 4px;
        border-color: #6aaaff;
        font-weight: 400;
        color: black;

    }
    &-add-button{
        background-color:white;
        padding: 1px 5px;
        border-radius: 4px;
        border-color: #0ac2c4;
        font-weight: 400;
        color: #0ac2c4;
        // font-size: 12px;
    }
    &-add-button:hover{
        background-color:#0ac2c4;
        padding: 1px 5px;
        border-radius: 4px;
        border-color: #0ac2c4;
        font-weight: 700;
        color: white;
    }
    &-add-masjid-button-for-web{
        width: 100px;
        background-color: #0ac2c4;
        border-color: #0ac2c4;
        color: white;
        padding: 5px 1px;
        font-weight: 600;
        font-size: 12px;
        border-radius: 6px;
        z-index: 1;
        float: right;
        margin-top: 8px;
    }
    &-tab-button-clicked{
        width: 100px;
        background-color: #00ad5c;
        border-color: #00ad5c;
        color: white;
        padding: 5px 1px;
        font-weight: 600;
        font-size: 12px;
        border-radius: 6px;
    }
    &-tab-button{
        width: 100px;
        color: #897e7e;
        border: 1px solid #f0f2f5;
        padding: 5px 1px;
        font-weight: 600;
        font-size: 12px;
        border-radius: 6px;
        background-color: #dbdde1;
    }
    &-clickable-heading{
        margin-bottom: 0.1rem;
        text-decoration: none;
        cursor: pointer;
    }
    &-clickable-heading:hover{
        margin-bottom: 0.1rem;
        text-decoration: underline;
        cursor: pointer;
    }
}
.my-masjid{
    &-no-data-found{
        padding: 1%;
        color: red;
        text-align: center;
    }
    &-add-masjid-button{
        background: #d8eb3d;
        border-color: #d8eb3d;
    }
}

.masjidList{
    &-card{
        background-color: #FCFDFF;
        border: 1px solid #F0F2F5;
        border-radius: 16px;
        padding: 0px 14.99px 0px 4.68px;
        margin: 0px 7px 10px 0px;
        min-height: 66px;
    }
    &-card-view-button{
        width: 125px;
        height: 38px;
        border-radius: 9px;
        font-weight: 500;
        line-height: 16.71px;
        cursor: pointer;
        font-size: 11px;
        margin: 1px 2px;
        color: #fff;
        background-color: #27BD65;
        border: 1px solid #27BD65;
        white-space: nowrap;
        outline: none;
    }
    &-card-add-button{
        width: 125px;
        height: 38px;
        border-radius: 9px;
        font-weight: 500;
        line-height: 16.71px;
        cursor: pointer;
        font-size: 13px;
        margin: 1px 2px;
        color: #2E2E2E;
        background-color: #FDC250;
        border: 1px solid #FDC250;
        outline: none;
    }
    &-card-follow-button{
        width: 125px;
        height: 38px;
        border-radius: 9px;
        font-weight: 500;
        line-height: 16.71px;
        cursor: pointer;
        font-size: 13px;
        margin: 1px 2px;
        border: 1px solid;
        color: black;
        background: rgba(0, 0, 0, 0);
        border-color: rgb(207, 217, 222);
        outline: none;
    }
    &-card-unfollow-button{
        width: 125px;
        height: 38px;
        border-radius: 9px;
        font-weight: 500;
        line-height: 16.71px;
        cursor: pointer;
        font-size: 13px;
        margin: 1px 2px;
        border: 1px solid;
        color: white;
        background: #1d9bf0;
        outline: none;
    }
    &-card-follow-button-for-mobile{
        width: 92px;
        height: 31px;
        border-radius: 9px;
        font-weight: 500;
        line-height: 16.71px;
        cursor: pointer;
        font-size: 10px;
        margin-bottom: 5px;
        border: 1px solid;
        color: black;
        background: rgba(0, 0, 0, 0);
        border-color: rgb(207, 217, 222);
        outline: none;
    }
    &-card-unfollow-button-for-mobile{
        width: 92px;
        height: 31px;
        border-radius: 9px;
        font-weight: 500;
        line-height: 16.71px;
        cursor: pointer;
        font-size: 10px;
        margin-bottom: 5px;
        border: 1px solid;
        color: white;
        background: #1d9bf0;
        outline: none;
    }
    &-card-for-mobile{
        background-color: #FCFDFF;
        border: 1px solid #F0F2F5;
        border-radius: 16px;
        padding: 0px 6px 0px 5px;
        margin: 0px 7px 10px 0px;
    }
    &-card-view-button-for-mobile{
        width: 92px;
        height: 31px;
        border-radius: 9px;
        font-weight: 500;
        line-height: 16.71px;
        cursor: pointer;
        font-size: 10px;
        margin: 5px 0px;
        color: #fff;
        background-color: #27BD65;
        border: 1px solid #27BD65;
        outline: none;
    }
    &-card-add-button-for-mobile{
        width: 92px;
        height: 31px;
        border-radius: 9px;
        font-weight: 500;
        line-height: 16.71px;
        cursor: pointer;
        font-size: 10px;
        color: #2E2E2E;
        background-color: #FDC250;
        border: 1px solid #FDC250;
        outline: none;
    }
    &-add-masjid-button{
        position: fixed;
        bottom: 30px;
        font-weight: 500;
        z-index: 3;
        cursor: pointer;
        right: 30px;
    }
    &-add-masjid-button-for-mobile{
        position: fixed;
        bottom: 100px;
        right: 20px;
        font-weight: 500;
        z-index: 4;
        cursor: pointer
    }
    &-scroll-masjid-button{
        position: fixed;
        bottom: 30px;
        background: #585858;
        z-index: 3;
        cursor: pointer;
        left: 30px;
        border-radius: 50px;
    }
    &-scroll-masjid-button-for-mobile{
        position: fixed;
        bottom: 100px;
        left: 20px;
        background: #585858 !important;
        z-index: 4;
        cursor: pointer;
        border-radius: 50px;
    }
    &-tabs-header-for-mobile{
        position: fixed;
        bottom: 0;
        font-weight: 500;
        z-index: 4;
        cursor: pointer;
        width: 100%;
        background-color: #FFFCF6;
        // padding-bottom: 15px;
    }
    &-inactive-tab{
        background-color: #FFFCF6;
        border: none;
        color: #000000;
        font-size: 11px; 
        font-style: normal;
        font-weight: 500;
        transition-duration: 0.5s;
    }
    &-active-tab-div{
        margin: 0px 3px;
        background-color: #E1C794;
        border: 1px solid #9a8356;
        color: black;
        width: 100px;
        height: 65px;
        border-radius: 12px;
        font-size: 20px;
        font-weight: 700;
        z-index:    4;
        transition-duration: 0.5s;
        position: absolute;
    }
    &-active-tab{
        background-color: #e1c794;
        border: none;
        color: #000000;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        word-wrap: no-wrap;
    }
}

.fixed-tabs-header{
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 3;
}
.absolute-tabs-header{
    position: absolute;
    width: 100%;
}
.fixed-search-bar{
    position: fixed;
    top: 9%;
    width: 75%;
    background-color: white;
    z-index: 3;
}
.nearby-event-List-card{
    background: #FCFDFF;
    border-radius: 14px;
    border: 1px solid #F0F2F5;
    margin-bottom: 10px;
    min-height: 70px;
}
.signIn-button{
    margin-top: 30px; 
    background-color: white;
    border: 1px solid #0000002b;
    width: 300px;
    height: 40px; 
}

@media only screen and (orientation: landscape) and (min-width : 601px) {
    .masjid-list-page-top-bar{
        display: block;
    }
    .masjid-list-page-bottom-bar{
        display: none;
    }
}

@media only screen and (orientation: portrait) and (max-width : 600px) {
    .masjid-list-page-top-bar{
        display: none;
    }
    .masjid-list-page-bottom-bar{
        display: flex;
    }
}

@media only screen and (min-width : 600px) and (max-width : 960px) {
    
    .masjidList-card-view-button{
        width: 120px;
        height: 35px;
        font-size: 12px;
    }
    .masjidList-card-add-button{
        width: 120px;
        height: 35px;
        font-size: 12px;
    }
    .masjidList-card-follow-button{
        width: 120px;
        height: 35px;
        font-size: 12px;
    }
    .masjidList-card-unfollow-button{
        width: 120px;
        height: 35px;
        font-size: 12px;
    }
}

@media only screen and (max-width : 300px) {
    
    .masjidList-card-view-button-for-mobile{
        width: 82px;
        height: 28px;
        font-size: 9px;
    }
    .masjidList-card-add-button-for-mobile{
        width: 82px;
        height: 28px;
        font-size: 9px;
    }
    .masjidList-card-follow-button-for-mobile{
        width: 82px;
        height: 28px;
        font-size: 9px;
    }
    .masjidList-card-unfollow-button-for-mobile{
        width: 82px;
        height: 28px;
        font-size: 9px;
    }
}



